export enum DocsVerificationCodesEnum {
    TAMPERED = '500-999',
    DATA = '1000-1999',
    MRZ = '2000-2999',
    EXPIRATION = '3000-3099',
    MISMATCH = '3100-3200',
    SELFIE = '4000-4499',
    POA = '4500-4999',
    LIVENESS = '5000-5499',
    AML_CHECK = '6000-6499',
    CROSS_CHECK = '7000-7499',
    EXPIRED = '8000',
    CANCELED = '8001',
    TOO_MANY_UPLOAD_FILES = '8002',
    REQUIRED_CHECK = '9000',
}
