import { ThemeModeEnum } from '@models/enums';
import type { BaseThemeProps, ThemeProps } from '@models/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { lightTheme } from '../../theme';

export type ThemeState = {
    currentThemeMode: ThemeModeEnum;
    currentTheme: BaseThemeProps;
};

let initTheme: ThemeProps | undefined;

try {
    const theme = localStorage.getItem('ds-theme');

    if (theme) {
        initTheme = JSON.parse(theme);
    }
} catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Error during parse ds-theme', e);
}

const initialState: ThemeState = {
    currentThemeMode: ThemeModeEnum.LIGHT,
    currentTheme: initTheme || lightTheme,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setIsLandscape(state, action: PayloadAction<boolean>) {
            state.currentTheme.isLandscape = action.payload;
        },
        setTheme(state, action: PayloadAction<BaseThemeProps>) {
            state.currentTheme = action.payload;
            localStorage.setItem('ds-theme', JSON.stringify(action.payload));
        },
    },
});
