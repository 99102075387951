import { adminPanelApi } from '@modules/admin-panel/api';
import { dashboardApi } from '@modules/dashboard/api';
import { sdkApi } from '@modules/docs-verification-widget/api';
import { publicApi } from '@modules/shared/api/public-base-api';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from '@store/root-reducer';
import LogRocket from 'logrocket';

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these paths in the state, they contain non-serializable functions
                ignoredPaths: [
                    'app.currentTheme.breakpointTo',
                    'app.currentTheme.breakpointFrom',
                    'app.currentTheme.breakpointBetween',
                    'toast.items',
                    'payload.file.file',
                    'dashboard.docsVerification.documents.DOCUMENT.files.main.file',
                    'dashboard.docsVerification.documents.DOCUMENT.files.back.file',
                    'dashboard.docsVerification.documents.DOCUMENT.files.front.file',
                    'dashboard.docsVerification.documents.BIOMETRICS.files.liveness_photo_extra.file',
                    'dashboard.docsVerification.documents.BIOMETRICS.files.selfie.file',
                    'dashboard.docsVerification.documents.BIOMETRICS.files.liveness_photo.file',
                    'dashboard.docsVerification.documents.ADDRESS.files.file',
                    'dashboard.docsVerificationFilters.filters.dateRange',
                    'dashboard.billingOperationsFilters.filters.dateRange',
                    'dashboard.billingPaymentsFilters.filters.dateRange',
                    'dashboard.applicantEditModalSlice.updateCallback',
                    'dashboard.applicantApplyModalSlice.updateCallback',
                    'dashboard.docsVerificationFiltersSlice.filters.dateRange',
                    'dashboard.applicantPersonFiltersSlice.filters.dateOfBirth',
                    'dashboard.verificationProfileManualFieldsValidation.validators',
                    'adminPanel.billingOperationsFilters.filters.dateRange',
                    'adminPanel.billingPaymentsFilters.filters.dateRange',
                    'adminPanel.billingPaymentsFilters.filters.dateRange',
                ],
                ignoredActions: ['toast/addToast'],
                ignoredActionPaths: [
                    'payload.dateRange',
                    'meta.arg',
                    'meta.baseQueryMeta',
                    'payload.validator',
                ],
            },
        }).concat(
            publicApi.middleware,
            dashboardApi.middleware,
            adminPanelApi.middleware,
            sdkApi.middleware,
            LogRocket.reduxMiddleware(),
        ),
});
