import { dashboardAccountApiSlice } from '@modules/dashboard/api/dashboard-account-api';
import { createSlice } from '@reduxjs/toolkit';

import { adminPanelAccountApiSlice } from '../../admin-panel/api/admin-panel-account-api';

import type {
    AuthUserGoogleLoginSliceState,
    AuthUserInfoSliceState,
    AuthUserRegistrationSliceState,
    AuthUserSliceState,
} from './types';
import { RequestStatus } from './types';
import {
    changePasswordPending,
    changePasswordRejected,
    changePasswordSuccess,
    googleLoginPending,
    googleLoginReject,
    googleLoginSuccess,
    infoPending,
    infoRejected,
    infoSuccess,
    loginPending,
    loginRejected,
    loginSuccess,
    logoutSuccess,
    registrationPending,
    registrationRejected,
    registrationSuccess,
    resetPasswordPending,
    resetPasswordRejected,
    resetPasswordSuccess,
} from './utils';

const initialStateRegistration: AuthUserRegistrationSliceState = {
    status: RequestStatus.EXPECTATION,
    message: undefined,
    email: undefined,
};

const initialStateUserInfo: AuthUserInfoSliceState = {
    status: RequestStatus.EXPECTATION,
    info: null,
};

const initialStateAuthUserGoogleLogin: AuthUserGoogleLoginSliceState = {
    status: RequestStatus.EXPECTATION,
};

const initialState: AuthUserSliceState = {
    authUserGoogle: initialStateAuthUserGoogleLogin,
    registration: initialStateRegistration,
    user: initialStateUserInfo,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearStateRegistration(state) {
            state.registration = initialStateRegistration;
            state.user = initialStateUserInfo;
            state.authUserGoogle = initialStateAuthUserGoogleLogin;
        },
    },
    extraReducers: (builder) => {
        // registration
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.registerAccount.matchFulfilled,
            registrationSuccess,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.registerAccount.matchPending,
            registrationPending,
        );

        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.registerAccount.matchRejected,
            registrationRejected,
        );

        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.registerAccount.matchFulfilled,
            registrationSuccess,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.registerAccount.matchPending,
            registrationPending,
        );

        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.registerAccount.matchRejected,
            registrationRejected,
        );

        // login
        builder.addMatcher(dashboardAccountApiSlice.endpoints.login.matchFulfilled, loginSuccess);
        builder.addMatcher(dashboardAccountApiSlice.endpoints.login.matchPending, loginPending);
        builder.addMatcher(dashboardAccountApiSlice.endpoints.login.matchRejected, loginRejected);

        builder.addMatcher(adminPanelAccountApiSlice.endpoints.login.matchFulfilled, loginSuccess);
        builder.addMatcher(adminPanelAccountApiSlice.endpoints.login.matchPending, loginPending);
        builder.addMatcher(adminPanelAccountApiSlice.endpoints.login.matchRejected, loginRejected);

        // googleLogin
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.googleLogin.matchFulfilled,
            googleLoginSuccess,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.googleLogin.matchPending,
            googleLoginPending,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.googleLogin.matchRejected,
            googleLoginReject,
        );

        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.googleLogin.matchFulfilled,
            googleLoginSuccess,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.googleLogin.matchPending,
            googleLoginPending,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.googleLogin.matchRejected,
            googleLoginReject,
        );

        /// resetPassword
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.resetPasswordRequest.matchFulfilled,
            resetPasswordSuccess,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.resetPasswordRequest.matchPending,
            resetPasswordPending,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.resetPasswordRequest.matchRejected,
            resetPasswordRejected,
        );

        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.resetPasswordRequest.matchFulfilled,
            resetPasswordSuccess,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.resetPasswordRequest.matchPending,
            resetPasswordPending,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.resetPasswordRequest.matchRejected,
            resetPasswordRejected,
        );

        /// changePassword
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.changePasswordByToken.matchFulfilled,
            changePasswordSuccess,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.changePasswordByToken.matchPending,
            changePasswordPending,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.changePasswordByToken.matchRejected,
            changePasswordRejected,
        );

        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.changePasswordByToken.matchFulfilled,
            changePasswordSuccess,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.changePasswordByToken.matchPending,
            changePasswordPending,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.changePasswordByToken.matchRejected,
            changePasswordRejected,
        );

        // logout
        builder.addMatcher(dashboardAccountApiSlice.endpoints.logout.matchFulfilled, logoutSuccess);
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.logout.matchFulfilled,
            logoutSuccess,
        );

        // info
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.getUserInfo.matchFulfilled,
            infoSuccess,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.getUserInfo.matchPending,
            infoPending,
        );
        builder.addMatcher(
            dashboardAccountApiSlice.endpoints.getUserInfo.matchRejected,
            infoRejected,
        );

        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.getUserInfo.matchFulfilled,
            infoSuccess,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.getUserInfo.matchPending,
            infoPending,
        );
        builder.addMatcher(
            adminPanelAccountApiSlice.endpoints.getUserInfo.matchRejected,
            infoRejected,
        );
    },
});

export const { clearStateRegistration } = authSlice.actions;
