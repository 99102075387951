import { createGlobalStyle } from 'styled-components';

export const AppGlobalStyles = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  body {
    margin: 0;
    padding: 0;

    * {
      margin: 0;
      font-family: 'Funnel Display', Roboto, -apple-system, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  ul {
    list-style: none;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
  }

  .MuiInputBase-root {
    font-family: 'Funnel Display', Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

  input {
    font-family: 'Funnel Display', Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  .spinnerWrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  body {
    a {
      &:hover {
        text-decoration: none;
      }
      
      &:focus {
        text-decoration: none;
        color: ${(props) => props.theme.palette.primaryA1};
      }
      
      &:active {
        text-decoration: none;
      }
    }
  }

  html, body, #root {
    height: 100vh;
  }

  button {
    cursor: pointer;
    border: none;
  }

  input:-webkit-autofill {
    background-color: transparent;
  }
`;
