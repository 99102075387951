import type {
    ApplicantBaseAlertTypeEnum,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
} from '@models/enums';
import { FilterNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type ApplicantAlertsFiltersState = {
    [FilterFieldArrayOptionNamesEnum.APPLICANT_ALERT_TYPE]: Array<
        AutocompleteOption<ApplicantBaseAlertTypeEnum>
    >;
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
};

export type ApplicantAlertsFiltersSliceState = FilterMenuProps<ApplicantAlertsFiltersState>;

export const applicantAlertsFiltersInitState: ApplicantAlertsFiltersState = {
    dateRange: null,
    applicantAlertTypes: [],
};

const initialState: ApplicantAlertsFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: applicantAlertsFiltersInitState,
    searchQuery: '',
};

export const applicantAlertsFiltersSlice = createSlice({
    name: FilterNames.APPLICANT_ALERTS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<ApplicantAlertsFiltersState, ApplicantAlertsFiltersSliceState>(
        applicantAlertsFiltersInitState,
    ),
});
