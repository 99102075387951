export enum PepQueryFieldEnum {
    SEARCH = 'search',
    TAGS = 'tags',
    LINKS = 'links',
    CATEGORIES = 'categories',
    COUNTRIES = 'countries',
    DATE_RANGE = 'dateRange',
    SEARCH_UUID = 'searchUuid',
    FUZZINESS_LEVEL = 'fuzzinessLevel',
    PHONETICS = 'phonetics',
    GENDER = 'gender',
    SEARCH_KYB = 'searchKyb',
}
