import type { OrganizationAccountInfoModel } from '@models';
import { OrganizationRoleEnum } from '@models/enums';
import type { OperationBalanceModel } from '@models/operation/operation-balance-model';
import { adminPanelOperationsApiSlice } from '@modules/admin-panel/api/admin-panel-operations-api';
import { adminPanelOrganizationsApiSlice } from '@modules/admin-panel/api/admin-panel-organizations-api';
import type { OrganizationInfoModel } from '@modules/admin-panel/models';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

export type AdminPanelSessionState = {
    organizationId: string;
    requestedOrganizationId: string;
    organizationsUpdateDate: string;
    organizationsList: OrganizationAccountInfoModel[];
    organizationsOwnedCount: number;
    organizationInfo?: OrganizationAccountInfoModel;
    organizationCreatedDate?: string;
    organizationFromListCreatedDate?: string;
    hasOperations?: boolean;
};

const initialState: AdminPanelSessionState = {
    organizationId: '',
    requestedOrganizationId: localStorage.getItem('admin-panel-organizationId') || '',
    organizationsUpdateDate: new Date().getTime().toString(),
    organizationInfo: undefined,
    organizationsList: [],
    organizationsOwnedCount: 0,
    organizationCreatedDate: undefined,
    organizationFromListCreatedDate: undefined,
};

export const adminPanelSessionSlice = createSlice({
    name: 'adminPanelSession',
    initialState,
    reducers: {
        clearSession: (state) => {
            localStorage.clear();
            localStorage.setItem('admin-panel-organizationId', state.organizationId);

            return initialState;
        },
        setRequestedOrganizationId: (state, { payload }: PayloadAction<string>) => {
            state.requestedOrganizationId = payload;
        },
        setOrganizationId: (state, { payload }: PayloadAction<string>) => {
            localStorage.setItem('admin-panel-organizationId', payload);
            state.organizationId = payload;
            state.organizationInfo = state.organizationsList.find(
                (item) => item.organization_id === payload,
            );
        },
        setOrganizationsList: (state, action: PayloadAction<OrganizationAccountInfoModel[]>) => {
            state.organizationsList = action.payload;
            let count = 0;

            if (Array.isArray(state.organizationsList) && state.organizationsList.length) {
                // eslint-disable-next-line no-restricted-syntax
                for (const organization of state.organizationsList) {
                    if (organization.organization_role === OrganizationRoleEnum.OWNER) {
                        count += 1;
                    }
                }
            }
            state.organizationsOwnedCount = count;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(adminPanelOperationsApiSlice.endpoints.getBalance.matchFulfilled),
            // eslint-disable-next-line complexity
            (state, action: PayloadAction<OperationBalanceModel>) => {
                if (!action.payload) {
                    return;
                }
                const balanceData = action.payload;

                state.hasOperations = balanceData.created_at !== balanceData.updated_at;
                state.organizationCreatedDate = balanceData.created_at;
            },
        );
        builder.addMatcher(
            adminPanelOrganizationsApiSlice.endpoints.getOrganizationInfo.matchFulfilled,
            (state, action: PayloadAction<OrganizationInfoModel>) => {
                if (!action.payload) {
                    return;
                }
                state.organizationFromListCreatedDate = action.payload.created_at;
            },
        );
    },
});
