export enum FilterBillingNames {
    BILLING_PAYMENTS_FILTERS_SLICE = 'billingPaymentsFiltersSlice',
    BILLING_OPERATIONS_FILTERS_SLICE = 'billingOperationsFiltersSlice',
}

export enum FilterOrganizationNames {
    ORGANIZATION_FILTERS_SLICE = 'organizationFiltersSlice',
    ORGANIZATION_ACCOUNTS_FILTERS_SLICE = 'organizationAccountsFiltersSlice',
    ORGANIZATION_AML_CHECKS_FILTERS_SLICE = 'organizationAmlChecksFiltersSlice',
    ORGANIZATION_EVENTS_FILTERS_SLICE = 'organizationEventsFiltersSlice',
}

export enum FilterAccountNames {
    ACCOUNT_EVENT_FILTERS_SLICE = 'accountEventFiltersSlice',
    ACCOUNT_FILTERS_SLICE = 'accountFiltersSlice',
}

export enum FilterDataSourcesNames {
    DATA_SOURCES_FILTERS_SLICE = 'dataSourcesFiltersSlice',
}

export enum FilterNames {
    AML_HISTORY_FILTERS_SLICE = 'amlHistoryFiltersSlice',
    DOCS_VERIFICATION_FILTERS_SLICE = 'docsVerificationFiltersSlice',
    APPLICANT_PERSON_FILTERS_SLICE = 'applicantPersonFiltersSlice',
    APPLICANT_ALERTS_FILTERS_SLICE = 'applicantAlertsFiltersSlice',
    APPLICANT_ORGANIZATION_FILTERS_SLICE = 'applicantOrganizationFiltersSlice',
    APPLICANT_DOCS_FILTERS_SLICE = 'applicantDocsFiltersSlice',
}
