import type {
    DocsVerificationStatusEnum,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    VerificationPhotosKeysEnum,
} from '@models/enums';
import { FilterNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type ApplicantDocsFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.DOCUMENT_TYPES_PHOTO]: Array<
        AutocompleteOption<VerificationPhotosKeysEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.STATUSES]: Array<
        AutocompleteOption<DocsVerificationStatusEnum>
    >;
};

export type ApplicantDocsFiltersSliceState = FilterMenuProps<ApplicantDocsFiltersState>;

export const applicantDocsFiltersInitState: ApplicantDocsFiltersState = {
    dateRange: null,
    documentTypesPhoto: [],
    statuses: [],
};

const initialState: ApplicantDocsFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: applicantDocsFiltersInitState,
    searchQuery: '',
};

export const applicantDocsFiltersSlice = createSlice({
    name: FilterNames.APPLICANT_DOCS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<ApplicantDocsFiltersState, ApplicantDocsFiltersSliceState>(
        applicantDocsFiltersInitState,
    ),
});
