import type {
    DocsVerificationDocumentTypeEnum,
    DocsVerificationStatusEnum,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldBooleanNamesEnum,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
} from '@models/enums';
import { FilterNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type DocsVerificationFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]?: AutocompleteOption<string> | null;
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.DOCUMENT_TYPES]: Array<
        AutocompleteOption<DocsVerificationDocumentTypeEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.PROFILES]: Array<AutocompleteOption<string>>;
    [FilterFieldArrayOptionNamesEnum.STATUSES]: Array<
        AutocompleteOption<DocsVerificationStatusEnum>
    >;
    [FilterFieldBooleanNamesEnum.ONLY_DOC_VER_MANUAL_STATUS]: boolean;
};

export type DocsVerificationFiltersSliceState = FilterMenuProps<DocsVerificationFiltersState>;

export const docsVerificationFiltersInitState: DocsVerificationFiltersState = {
    country: null,
    dateRange: null,
    documentTypes: [],
    profiles: [],
    statuses: [],
    onlyDocVerManualStatus: false,
};

const initialState: DocsVerificationFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: docsVerificationFiltersInitState,
    searchQuery: '',
};

export const docsVerificationFiltersSlice = createSlice({
    name: FilterNames.DOCS_VERIFICATION_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<DocsVerificationFiltersState, DocsVerificationFiltersSliceState>(
        docsVerificationFiltersInitState,
    ),
});
