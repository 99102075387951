export enum DocumentsStepEnum {
    UPLOAD_FIRST_SIDE,
    UPLOAD_SECOND_SIDE,
    SELECT_COUNTRY,
}

export type DocumentsProps = {
    onSubmit: () => Promise<void>;
    onBack: () => void;
    isSubmitting: boolean;
};
