import { combineReducers } from '@reduxjs/toolkit';
import { amlHistoryFiltersSlice } from '@store/slices/filters/aml-history-filters';
import {
    applicantAlertsFiltersSlice,
    applicantDocsFiltersSlice,
    applicantOrganizationFiltersSlice,
    applicantPersonFiltersSlice,
} from '@store/slices/filters/applicant-filters';
import { docsVerificationFiltersSlice } from '@store/slices/filters/docs-verification-filters';

export const filterReducer = combineReducers({
    amlHistoryFiltersSlice: amlHistoryFiltersSlice.reducer,
    docsVerificationFiltersSlice: docsVerificationFiltersSlice.reducer,
    applicantPersonFiltersSlice: applicantPersonFiltersSlice.reducer,
    applicantOrganizationFiltersSlice: applicantOrganizationFiltersSlice.reducer,
    applicantAlertsFiltersSlice: applicantAlertsFiltersSlice.reducer,
    applicantDocsFiltersSlice: applicantDocsFiltersSlice.reducer,
});
