import type { OrganizationRoleEnum } from '@models/enums';
import type { AuthUserInfo } from '@modules/auth/store';

export const transformUserInfo = (authInfo: AuthUserInfo) => {
    const obj = {
        ...authInfo,
    };

    if (obj?.logo_url) {
        obj.logo_url = `${obj.logo_url}?t=${new Date().getTime()}`;
    }

    if (obj?.global_role) {
        obj.global_role = obj.global_role?.toUpperCase() as OrganizationRoleEnum;
    }

    return obj;
};
