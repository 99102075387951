import type { BaseThemeProps } from '@models/types';

import { lightTheme as mainTheme } from '../../theme';

export const lightTheme: BaseThemeProps = {
    ...mainTheme,
    palette: {
        textMain: '#000000',
        textLight: '#747391',
        primaryA1: '#6764E8', // '#8DC63F'
        primaryA2: '#9290EE', // '#B9E083'
        primaryA3: '#AFAFF0', // '#D1E4B6'
        primaryA4: '#D8DEFB', // '#F1FFDD'
        primaryA5: '#E1E7FC', // '#F8FFEF'
        primaryA6: '#EAF1FE', // ?
        primaryA7: '#FFFFFF',
        secondaryA1: '#F6CDD9',
        secondaryA2: '#F6F0C0',
        secondaryA3: '#52C27F',
        secondaryA4: '#FF5387',
        secondaryA5: '#FFFCFD',
        secondaryA6: '#FABC46',
        secondaryA7: '#B25DF5',
        secondaryA8: '#BBBAC9', // ?
        secondaryA9: 'rgba(103, 100, 232, 0.1)', // ?
        secondaryA10: '#F6F8FE', // ?
    },
    components: {
        sideMenu: {
            ...mainTheme.components.sideMenu,
            backgroundColor: '#0C1531',
            menuItem: {
                color: '#747391',
                hover: {
                    color: '#9290EE',
                },
                selected: {
                    backgroundColor: 'rgb(17, 30, 70)',
                    color: '#FFFFFF',
                },
                open: {
                    backgroundColor: '#e1e7fc',
                    color: '#747391',
                },
            },
        },
    },
};
