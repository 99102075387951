import React from 'react';
import type { IconProps } from '@models/types';

export const MobileIcon: React.FC<IconProps> = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
        <path
            d='M10 17.4997C10.4602 17.4997 10.8333 17.1266 10.8333 16.6663C10.8333 16.2061 10.4602 15.833 10 15.833C9.53976 15.833 9.16666 16.2061 9.16666 16.6663C9.16666 17.1266 9.53976 17.4997 10 17.4997Z'
            fill='#747391'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.66666 0.833008C5.28595 0.833008 4.16666 1.9523 4.16666 3.33301V17.4997C4.16666 18.8804 5.28595 19.9997 6.66666 19.9997H13.3333C14.714 19.9997 15.8333 18.8804 15.8333 17.4997V3.33301C15.8333 1.9523 14.714 0.833008 13.3333 0.833008H6.66666ZM5.83333 3.33301C5.83333 2.87277 6.20643 2.49967 6.66666 2.49967H13.3333C13.7936 2.49967 14.1667 2.87277 14.1667 3.33301V4.16634H5.83333V3.33301ZM5.83333 5.83301V13.333H14.1667V5.83301H5.83333ZM5.83333 17.4997V14.9997H14.1667V17.4997C14.1667 17.9599 13.7936 18.333 13.3333 18.333H6.66666C6.20643 18.333 5.83333 17.9599 5.83333 17.4997Z'
            fill='currentColor'
        />
    </svg>
);
