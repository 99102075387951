import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from '@global-styles';
import { createLocalization } from '@lang/localization';
import { LangEnum } from '@models/enums';
import CssBaseline from '@mui/material/CssBaseline';
import type { ThemeOptions } from '@mui/material/styles';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { StoreWrapperProps } from '@root/types';
import { store } from '@store';
import type { RootState } from '@store/root-reducer';
import i18n from 'i18next';
import type { DefaultTheme } from 'styled-components';
import { ThemeProvider } from 'styled-components';

import { App } from './app';
import { generateMediaHelpers, theme } from './theme';

setupListeners(store.dispatch);
createLocalization([LangEnum.EN], LangEnum.EN);

const AppWithTheme = () => {
    const currentTheme = useSelector((state: RootState) => state.theme.currentTheme);

    const mainTheme = useMemo(
        () =>
            createTheme(
                { ...(currentTheme as ThemeOptions) },
                theme,
                generateMediaHelpers(currentTheme),
            ),
        [currentTheme],
        // we added additional values in createTheme, need to cast for styled ThemeProvider
    ) as unknown as DefaultTheme;

    return (
        <StyledEngineProvider injectFirst={true}>
            <MuiThemeProvider theme={mainTheme}>
                <ThemeProvider theme={mainTheme}>
                    <CssBaseline>
                        <GlobalStyles />
                        <App />
                    </CssBaseline>
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
};

const StoreWrapper = ({ children }: StoreWrapperProps) => (
    <Provider store={store}>{children}</Provider>
);

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <StoreWrapper>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <AppWithTheme />
                    </BrowserRouter>
                </I18nextProvider>
            </StoreWrapper>
        </React.StrictMode>,
    );
}
