import type { PepEntityRiskScoreEnum } from '@models/enums';
import {
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
    FilterNames,
} from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type AmlHistoryFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: Array<
        AutocompleteOption<PepEntityRiskScoreEnum>
    >;
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: AutocompleteOption<string> | null;
};

export type AmlHistoryFiltersSliceState = FilterMenuProps<AmlHistoryFiltersState>;

export const amlHistoryFiltersInitState: AmlHistoryFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: null,
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: [],
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: null,
};

const initialState: AmlHistoryFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: amlHistoryFiltersInitState,
    searchQuery: '',
};

export const amlHistoryFiltersSlice = createSlice({
    name: FilterNames.AML_HISTORY_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<AmlHistoryFiltersState, AmlHistoryFiltersSliceState>(
        amlHistoryFiltersInitState,
    ),
});
