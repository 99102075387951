import { FilterBillingNames, FilterDataSourcesNames } from '@models/enums';
import { adminPanelApi } from '@modules/admin-panel/api';
import { adminPanelReducer } from '@modules/admin-panel/store';
import { authSlice } from '@modules/auth/store';
import { dashboardApi } from '@modules/dashboard/api/dashboard-base-api';
import { sdkApi } from '@modules/docs-verification-widget/api/sdk-base-api';
import { publicApi } from '@modules/shared/api/public-base-api';
import { domainSlice } from '@modules/shared/slices/domain-slice';
import { combineReducers } from '@reduxjs/toolkit';
import { billingOperationsFiltersSlice, billingPaymentsFiltersSlice } from '@store/slices/billing';
import { docsVerificationSlice } from '@store/slices/docs-verification';
import { filterReducer } from '@store/slices/filters/filter-reducer';
import { logoutSlice } from '@store/slices/logout-slice';
import { modalReducer } from '@store/slices/modals/modal-reducer';
import { dataSourcesFiltersSlice, pepFiltersSlice } from '@store/slices/pep';

import { appSlice } from './slices/app-slice';
import { infiniteScrollTableSlice } from './slices/infinite-scroll-table-slice';
import { operationHistorySlice } from './slices/operation-history-slice';
import { sessionSlice } from './slices/session-slice';
import { themeSlice } from './slices/theme-slice';
import { toastSlice } from './slices/toast-slice';

export const rootReducer = combineReducers({
    domain: domainSlice.reducer,
    theme: themeSlice.reducer,
    auth: authSlice.reducer,
    toast: toastSlice.reducer,
    logout: logoutSlice.reducer,
    infinityScrollTable: infiniteScrollTableSlice.reducer,
    docsVerification: docsVerificationSlice.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    dashboard: combineReducers({
        app: appSlice.reducer,
        [FilterDataSourcesNames.DATA_SOURCES_FILTERS_SLICE]: dataSourcesFiltersSlice.reducer,
        session: sessionSlice.reducer,
        operationHistory: operationHistorySlice.reducer,
        pepFilters: pepFiltersSlice.reducer,
        [FilterBillingNames.BILLING_OPERATIONS_FILTERS_SLICE]:
            billingOperationsFiltersSlice.reducer,
        [FilterBillingNames.BILLING_PAYMENTS_FILTERS_SLICE]: billingPaymentsFiltersSlice.reducer,
    }),
    [adminPanelApi.reducerPath]: adminPanelApi.reducer,
    adminPanel: adminPanelReducer,
    [sdkApi.reducerPath]: sdkApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    modals: modalReducer,
    filters: filterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
