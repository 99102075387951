import React from 'react';
import type { IconProps } from '@models/types';

export const IpadIcon: React.FC<IconProps> = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 3C2 1.34315 3.34315 0 5 0H15C16.6569 0 18 1.34315 18 3V17C18 18.6569 16.6569 20 15 20H5C3.34315 20 2 18.6569 2 17V3ZM4 3C4 2.44772 4.44772 2 5 2H15C15.5523 2 16 2.44772 16 3V15C16 15.5523 15.5523 16 15 16H5C4.44772 16 4 15.5523 4 15V3ZM10 19C10.5523 19 11 18.5523 11 18C11 17.4477 10.5523 17 10 17C9.44772 17 9 17.4477 9 18C9 18.5523 9.44772 19 10 19Z'
            fill='currentColor'
        />
    </svg>
);
