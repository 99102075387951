import type {
    AcceptInviteRequest,
    CreateOrganizationInfoRequest,
    InviteTeamMemberRequest,
    OnboardingQuestionnaireRequest,
    OrganizationBillingInfoModel,
    OrganizationInfoModel,
    RemoveTeamMemberFromOrganisationRequest,
    SetManageDocverProductRequest,
    TeamMemberModel,
    UpdateOrganizationInfoRequest,
    UpdateTeamMemberRoleRequest,
} from '@models';
import { AccountStatus } from '@models';
import { ApiTagEnum } from '@models/enums';
import type { InvitationModel } from '@models/organisation/invite/invitation-model';
import type { OrganizationProductSettingModel } from '@models/organisation/organization-product-settings-model';
import type { WithPaginationRequest } from '@models/requests';
import type { PaginationListResponse } from '@models/types';
import { transformOrganization } from '@utils/transform-response';

import { dashboardApi } from './dashboard-base-api';

const URL_PREFIX = 'webapi/organization/';
const URL_PREFIX_NEW = 'webapi/v4/organization/';

export const LimitOrganizationCount = 5;
export const LimitOrganizationOwnedByOneUserCount = 5;

export const organizationsApiSlice = dashboardApi.injectEndpoints({
    endpoints: (build) => ({
        getInitialOrganizationList: build.query<OrganizationInfoModel[], void>({
            query: () => `${URL_PREFIX}list?page=0&limit=10`,
            transformResponse: (response: PaginationListResponse<OrganizationInfoModel>) =>
                response?.data
                    ?.map((org) => transformOrganization(org) as OrganizationInfoModel)
                    .filter((org) => org.account_status?.toUpperCase() === AccountStatus.ACTIVE),
        }),
        getInitialOrganizationInfo: build.query<OrganizationInfoModel, string>({
            query: (organizationId) => `${URL_PREFIX}${organizationId}/info`,
            transformResponse: (response: OrganizationInfoModel) =>
                transformOrganization(response) as OrganizationInfoModel,
        }),
        getOrganizationInfo: build.query<OrganizationInfoModel, string>({
            query: (organizationId) => `${URL_PREFIX}${organizationId}/info`,
            transformResponse: (response: OrganizationInfoModel) =>
                transformOrganization(response) as OrganizationInfoModel,
            providesTags: [ApiTagEnum.CURRENT_ORGANIZATION],
        }),
        getProductSettings: build.query<OrganizationProductSettingModel[], string>({
            query: (organizationId) => `${URL_PREFIX_NEW}${organizationId}/product-settings`,
            transformResponse: (response: { [key: string]: OrganizationProductSettingModel }) =>
                Object.values(response),
        }),
        getInitialOrganizationBillingInfo: build.query<OrganizationBillingInfoModel, string>({
            query: (organizationId) => `${URL_PREFIX}${organizationId}/billing-info`,
        }),
        getOrganizationBillingInfo: build.query<OrganizationBillingInfoModel, string>({
            query: (organizationId) => `${URL_PREFIX}${organizationId}/billing-info`,
            providesTags: [ApiTagEnum.CURRENT_BILLING_ORGANIZATION],
        }),
        getInvitesList: build.query<InvitationModel[], void>({
            query: () => `${URL_PREFIX}list-invites?page=0&limit=5`,
            transformResponse: (response: PaginationListResponse<InvitationModel>) =>
                response?.data?.filter((invitation) => !!invitation.invite_token),
            providesTags: [ApiTagEnum.INVITATIONS],
        }),
        getListTeam: build.query<
            PaginationListResponse<TeamMemberModel>,
            { organizationId: string } & WithPaginationRequest
        >({
            query: ({ organizationId, page, limit = 10 }) =>
                `${URL_PREFIX}${organizationId}/team?page=${page}&limit=${limit}`,
            transformResponse: (response: PaginationListResponse<TeamMemberModel>) => {
                const members = response.data?.map(
                    (member) => transformOrganization(member) as TeamMemberModel,
                );
                const newResponse = {
                    ...response,
                    data: members,
                };

                return newResponse;
            },
            providesTags: [ApiTagEnum.LIST_TEAM],
        }),
        getAccountEmails: build.query<
            PaginationListResponse<string, false>,
            { organizationId: string }
        >({
            query: ({ organizationId }) => `${URL_PREFIX}${organizationId}/account-emails`,
        }),
        acceptInvitation: build.mutation<{ message: string }, AcceptInviteRequest>({
            query: ({ params, body }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/invite/accept`,
                body,
            }),
            invalidatesTags: [ApiTagEnum.INVITATIONS],
        }),
        declineInvitation: build.mutation<{ message: string }, AcceptInviteRequest>({
            query: ({ params, body }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/invite/decline`,
                body,
            }),
            invalidatesTags: [ApiTagEnum.INVITATIONS],
        }),
        inviteTeamMember: build.mutation<{ id: string }, InviteTeamMemberRequest>({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/invite`,
                body,
            }),
        }),
        updateTeamMemberRole: build.mutation<{ id: string }, UpdateTeamMemberRoleRequest>({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/promote`,
                body,
            }),
        }),
        removeTeamMemberFromOrganization: build.mutation<
            { message: string },
            RemoveTeamMemberFromOrganisationRequest
        >({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/remove`,
                body,
            }),
            invalidatesTags: [ApiTagEnum.LIST_TEAM],
        }),
        leaveFromOrganization: build.mutation<{ message: string }, string>({
            query: (organizationId) => ({
                method: 'POST',
                url: `${URL_PREFIX}${organizationId}/leave`,
            }),
            invalidatesTags: [ApiTagEnum.LIST_TEAM],
        }),
        createOrganization: build.mutation<{ message: string }, CreateOrganizationInfoRequest>({
            query: ({ body }) => ({
                method: 'POST',
                url: `${URL_PREFIX}create`,
                body,
            }),
            invalidatesTags: [ApiTagEnum.ORGANIZATIONS],
        }),
        updateOrganization: build.mutation<{ message: string }, UpdateOrganizationInfoRequest>({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/update`,
                body,
            }),
            invalidatesTags: [ApiTagEnum.ORGANIZATIONS, ApiTagEnum.CURRENT_ORGANIZATION],
        }),
        setManageDocverProduct: build.mutation<{ message: string }, SetManageDocverProductRequest>({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/product/docver`,
                body,
            }),
            invalidatesTags: [ApiTagEnum.CURRENT_ORGANIZATION],
        }),
        onboardingQuestionnaire: build.mutation<
            { message: string },
            OnboardingQuestionnaireRequest
        >({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}${params.organizationId}/questionnaire`,
                body,
            }),
        }),
    }),
});

export const {
    useLazyGetInitialOrganizationListQuery,
    useGetInvitesListQuery,
    useLazyGetInitialOrganizationInfoQuery,
    useGetOrganizationBillingInfoQuery,
    useGetProductSettingsQuery,
    useLazyGetOrganizationInfoQuery,
    useLazyGetInitialOrganizationBillingInfoQuery,
    useLazyGetListTeamQuery,
    useLazyGetAccountEmailsQuery,
    useInviteTeamMemberMutation,
    useUpdateTeamMemberRoleMutation,
    useRemoveTeamMemberFromOrganizationMutation,
    useLeaveFromOrganizationMutation,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useAcceptInvitationMutation,
    useDeclineInvitationMutation,
    useOnboardingQuestionnaireMutation,
} = organizationsApiSlice;
