import type {
    PepReportHistoryModel,
    PepReportModel,
    PepReportsModel,
    SearchPepHistoryRequest,
    SearchPepReportFrontendRequest,
} from '@models';
import { ApiTagEnum } from '@models/enums';
import type { WithPaginationRequest } from '@models/requests';
import type { PaginationListResponse } from '@models/types';
import { createSelector } from '@reduxjs/toolkit';

import { dashboardApi } from './dashboard-base-api';

const URL_PREFIX = 'webapi/v3/';

export const pepsApiSlice = dashboardApi.injectEndpoints({
    endpoints: (build) => ({
        getPepList: build.mutation<
            PepReportsModel,
            WithPaginationRequest & {
                body: SearchPepReportFrontendRequest;
                params: {
                    organizationId: string;
                };
            }
        >({
            query({ body, params }) {
                return {
                    url: `${URL_PREFIX}aml/${params.organizationId}/search`,
                    method: 'POST',
                    body: {
                        full_name: body.fullName,
                        countries: body.countries,
                        gender: body.gender,
                        cities: body.cities,
                        entity_types: body.entityTypes,
                        postal_codes: body.postalCodes,
                        date_of_birth: body.dateOfBirth,
                        tags: body.tags,
                        sources: body.sources,
                        registration_ids: body.registrationIds,
                        fuzziness_level: body.fuzzinessLevel,
                        phonetics: body.phonetics,
                        fuzziness: !!(body?.fuzzinessLevel && body?.fuzzinessLevel > 0),
                        search_kyb: body.searchKyb,
                    },
                };
            },
        }),
        getPepSearchResultById: build.query<
            PepReportModel,
            { organizationId: string; reportUuid: string }
        >({
            query: ({ organizationId, reportUuid }) =>
                `${URL_PREFIX}aml/${organizationId}/search/${reportUuid}`,
            providesTags: [ApiTagEnum.PEP],
        }),
        getPepById: build.query<
            PepReportModel,
            { organizationId: string; searchUuid: string; reportUuid: string }
        >({
            query: ({ organizationId, searchUuid, reportUuid }) =>
                `${URL_PREFIX}aml/${organizationId}/search/history/result/${searchUuid}/entity/${reportUuid}`,
            providesTags: [ApiTagEnum.PEP],
        }),
        getAutocomplete: build.query<
            string[],
            { organizationId: string; name: string; searchKyb: boolean }
        >({
            query: ({ organizationId, name, ...params }) => ({
                url: `${URL_PREFIX}aml/${organizationId}/autocomplete/${name}`,
                params,
            }),
        }),
        getPepHistoryList: build.query<
            PaginationListResponse<PepReportHistoryModel>,
            SearchPepHistoryRequest
        >({
            query: ({ organizationId, limit = 20, accountEmail, ...params }) => ({
                url: `${URL_PREFIX}aml/${organizationId}/search/history`,
                params: {
                    limit,
                    account: accountEmail,
                    ...params,
                },
            }),
        }),
        getPepHistoryById: build.query<
            PepReportHistoryModel,
            { organizationId: string; id: string }
        >({
            query: ({ organizationId, id }) =>
                `${URL_PREFIX}aml/${organizationId}/search/history/${id}`,
        }),
        getPepListByUuid: build.query<
            PepReportsModel,
            { organizationId: string; searchUuid: string }
        >({
            query: ({ organizationId, searchUuid }) =>
                `${URL_PREFIX}aml/${organizationId}/search/history/result/${searchUuid}`,
        }),
    }),
});

export const {
    useGetPepListMutation,
    useLazyGetAutocompleteQuery,
    useLazyGetPepHistoryListQuery,
    useLazyGetPepHistoryByIdQuery,
    useLazyGetPepListByUuidQuery,
    useLazyGetPepSearchResultByIdQuery,
    useLazyGetPepByIdQuery,
    useGetPepListByUuidQuery,
} = pepsApiSlice;

// PepSearchResultById
export const selectPepSearchResultById = (organizationId: string, reportUuid: string) =>
    createSelector(
        pepsApiSlice.endpoints.getPepSearchResultById.select({ organizationId, reportUuid }),
        (state) => state,
    );
export const selectPepById = (organizationId: string, reportUuid: string, searchUuid: string) =>
    createSelector(
        pepsApiSlice.endpoints.getPepById.select({ organizationId, reportUuid, searchUuid }),
        (state) => state,
    );
