import React from 'react';
import type { IconProps } from '@models/types';

export const RobotIcon: React.FC<IconProps> = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13 13C14.1046 13 15 12.1046 15 11C15 9.89543 14.1046 9 13 9C11.8954 9 11 9.89543 11 11C11 12.1046 11.8954 13 13 13ZM13 12C13.1821 12 13.3529 11.9513 13.5 11.8662C13.2011 11.6933 13 11.3701 13 11C13 10.6299 13.2011 10.3067 13.5 10.1338C13.3529 10.0487 13.1821 10 13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 11C10 12.1046 9.10457 13 8 13C6.89543 13 6 12.1046 6 11C6 9.89543 6.89543 9 8 9C9.10457 9 10 9.89543 10 11ZM8.5 11.8662C8.35291 11.9513 8.18214 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.18214 10 8.35291 10.0487 8.5 10.1338C8.2011 10.3067 8 10.6299 8 11C8 11.3701 8.2011 11.6933 8.5 11.8662Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14 2H12V5H8V2H6V5C3.79086 5 2 6.79086 2 9H0V11.7449C0 12.1081 0.196892 12.4427 0.514357 12.6191L2 13.4444V15C2 17.2091 3.79086 19 6 19H14C16.2091 19 18 17.2091 18 15V13.4444L19.4856 12.6191C19.8031 12.4427 20 12.1081 20 11.7449V9H18C18 6.79086 16.2091 5 14 5V2ZM4 9C4 7.89543 4.89543 7 6 7H14C15.1046 7 16 7.89543 16 9V15C16 16.1046 15.1046 17 14 17H6C4.89543 17 4 16.1046 4 15V9Z'
            fill='currentColor'
        />
    </svg>
);
