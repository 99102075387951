import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '@store/slices/shared/logout-action';

export type LogoutState = {
    requestedLogout: boolean;
    sessionExpired: boolean;
};

const initialState: LogoutState = {
    sessionExpired: false,
    requestedLogout: false,
};

export const logoutSlice = createSlice({
    name: 'logout',
    initialState,
    reducers: {
        resetRequestedLogout: (state) => {
            state.requestedLogout = false;
        },
        setSessionExpired: (state, { payload }: PayloadAction<boolean>) => {
            state.sessionExpired = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logOut.type, (state) => {
            if (!state.requestedLogout) {
                state.requestedLogout = true;
            }
        });
    },
});
