import React from 'react';
import type { IconProps } from '@models/types';

export const CalendarIcon: React.FC<IconProps> = (props) => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V2H3C1.34315 2 0 3.34315 0 5V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V5C20 3.34315 18.6569 2 17 2H16V1C16 0.447715 15.5523 0 15 0C14.4477 0 14 0.447715 14 1V2H6V1ZM3 4C2.44772 4 2 4.44772 2 5V7H18V5C18 4.44772 17.5523 4 17 4H3ZM18 9H2V17C2 17.5523 2.44772 18 3 18H17C17.5523 18 18 17.5523 18 17V9Z'
            fill='currentColor'
        />
    </svg>
);
