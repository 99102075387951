import { FilterAccountNames, FilterOrganizationNames } from '@models/enums';
import { combineReducers } from '@reduxjs/toolkit';
import { billingOperationsFiltersSlice } from '@store/slices/billing/billing-operations-filters-slice';
import { billingPaymentsFiltersSlice } from '@store/slices/billing/billing-payments-filters-slice';

import {
    accountEventFiltersSlice,
    accountFiltersSlice,
    adminPanelSessionSlice,
    adminPanelSlice,
    organizationAccountsFiltersSlice,
    organizationAmlChecksFiltersSlice,
    organizationEventsFiltersSlice,
    organizationFiltersSlice,
    paymentHistoryFiltersSlice,
} from './slices';

export const adminPanelReducer = combineReducers({
    app: adminPanelSlice.reducer,
    [FilterAccountNames.ACCOUNT_FILTERS_SLICE]: accountFiltersSlice.reducer,
    [FilterOrganizationNames.ORGANIZATION_FILTERS_SLICE]: organizationFiltersSlice.reducer,
    [FilterAccountNames.ACCOUNT_EVENT_FILTERS_SLICE]: accountEventFiltersSlice.reducer,
    [FilterOrganizationNames.ORGANIZATION_ACCOUNTS_FILTERS_SLICE]:
        organizationAccountsFiltersSlice.reducer,
    [FilterOrganizationNames.ORGANIZATION_AML_CHECKS_FILTERS_SLICE]:
        organizationAmlChecksFiltersSlice.reducer,
    [FilterOrganizationNames.ORGANIZATION_EVENTS_FILTERS_SLICE]:
        organizationEventsFiltersSlice.reducer,
    paymentHistoryFilters: paymentHistoryFiltersSlice.reducer,
    billingPaymentsFilters: billingPaymentsFiltersSlice.reducer,
    billingOperationsFilters: billingOperationsFiltersSlice.reducer,
    session: adminPanelSessionSlice.reducer,
});
