import type { FilterInitialModel } from '@models';
import {
    FilterFieldArrayOptionNamesEnum,
    FilterFieldBooleanNamesEnum,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
    FilterFieldTypeEnum,
} from '@models/enums';
import type { FilterMenuProps } from '@models/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const getFiltersCount = (filters: Record<string, unknown>) => {
    let count = 0;
    const ignoreFiltersKey = ['organizations'];

    Object.keys(filters).forEach((key) => {
        if (!ignoreFiltersKey.includes(key)) {
            const val = filters[key];

            if (Array.isArray(val)) {
                if (val.length) {
                    count += 1;
                }

                return;
            }

            if (val) {
                count += 1;
            }
        }
    });

    return count;
};

export function getFiltersReducer<Filters, State extends FilterMenuProps<Filters>>(
    initState: Filters,
) {
    return {
        openFiltersMenu: (state: State) => {
            state.isOpen = true;
        },
        hideFiltersMenu: (state: State) => {
            state.isOpen = false;
        },
        setFilterValue: (state: State, { payload }: PayloadAction<Partial<Filters>>) => ({
            ...state,
            filters: {
                ...state.filters,
                ...payload,
            },
        }),
        increaseApplyCount: (state: State) => {
            state.applyCount += 1;
        },

        cleanFilters: (state: State) => {
            state.applyCount = 0;
            state.filters = initState;
        },
        cleanSearchQuery: (state: State) => {
            state.applyCount = 0;
            state.searchQuery = '';
        },
        cleanAll: (state: State) => {
            state.applyCount = 0;
            state.filters = initState;
            state.searchQuery = '';
        },
        setSearchQuery: (state: State, { payload }: PayloadAction<string>) => {
            state.searchQuery = payload;
        },
        setFilterValues: (state: State, { payload }: PayloadAction<FilterInitialModel>) => {
            state.applyCount += 1;
            state.filters = {
                ...state.filters,
                ...payload.filters,
            };

            state.searchQuery = payload.searchQuery;
        },
    };
}

export const getFilterFieldType = (key: string) => {
    if (
        Object.values(FilterFieldArrayOptionNamesEnum).includes(
            key as FilterFieldArrayOptionNamesEnum,
        )
    ) {
        return FilterFieldTypeEnum.ARRAY_OPTION;
    }
    // if (Object.values(FilterFieldStringNamesEnum).includes(key as FilterFieldStringNamesEnum)) {
    //     return FilterFieldTypeEnum.STRING;
    // }

    if (Object.values(FilterFieldOptionNamesEnum).includes(key as FilterFieldOptionNamesEnum)) {
        return FilterFieldTypeEnum.OPTION;
    }
    if (Object.values(FilterFieldDateNamesEnum).includes(key as FilterFieldDateNamesEnum)) {
        return FilterFieldTypeEnum.DATE;
    }
    if (Object.values(FilterFieldBooleanNamesEnum).includes(key as FilterFieldBooleanNamesEnum)) {
        return FilterFieldTypeEnum.BOOLEAN;
    }

    return null;
};
